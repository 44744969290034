<template>
  <b-modal :id="id" :title="id" size="xl" lazy centered
           body-class="w-100" hide-header hide-footer @show="load">
    <template #default>
      <b-overlay :show="true" bg-color="white" opacity="1" style="min-height: 100px;">
        <template v-if="!studentEvents.loading">
            <b-table-simple v-for="bus in buses" :key="bus.id" responsive caption-top class="d-none d-print-block">
              <caption class="p-0">
                <div class="d-flex justify-content-center gap-1">
                  <b-img :src="logo" class="print-logo" alt="Logo"/>
                  <div class="text-body d-flex flex-column align-self-center">
                    <div class="font-weight-bold font-medium-2">Bus Roster</div>
                    <div class="font-weight-bold font-small-4">All-State - {{ $store.getters['settings/getCurrentYear'] }}</div>
                  </div>
                </div>
              </caption>
              <b-thead>
                <b-tr>
                  <b-th colspan="2" class="text-left font-weight-bolder font-small-1 py-0">Bus {{ bus.name }}</b-th>
                  <b-th colspan="2" class="text-right font-weight-bolder font-small-1 py-0">{{ getStudentEventsForBus(bus.id).length }} Students</b-th>
                </b-tr>
<!--                <b-tr>
                  <b-th colspan="8" class="`font-small-1"></b-th>
                </b-tr>-->
                <b-tr>
                  <b-th class="col-25-percent font-small-1">Student</b-th>
                  <b-th class="col-15-percent font-small-1">Ensemble</b-th>
                  <b-th class="col-15-percent font-small-1 text-center">Checkin Status</b-th>
                  <b-th class="font-small-1">Note</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="event in getStudentEventsForBus(bus.id)" :key="event.id"
                      :data="application = event.student ? getApplicationWithSelection(event.student.applications) : null">
                  <b-td>
                    <span v-if="event.student" class="font-small-1">
                      {{ getFullName(event.student, { lnf: true }) }}
                    </span>
                    <span v-else class="text-danger font-small-1">
                      No Student
                    </span>
                  </b-td>
                  <b-td>
                    <span v-if="application && application.selection && application.selection.ensemble" class="font-small-1">
                       {{ application.selection.ensemble.name }}
                    </span>
                    <span v-else class="text-danger font-small-1">
                      Unknown Ensemble
                    </span>
                  </b-td>
                  <b-td class="text-center">
                    <b-icon v-for="n in 4" :key="n"
                            icon="square"
                            class="text-left px-0 align-items-start font-small-1"
                            :class="n !== 1 ? 'ml-1' : ''" style="width: 10px; height: 10px;"/>
                  </b-td>
                  <b-td class="text-wrap-balanc font-small-1">
                    {{ getBusNotesFromStudentEvent(event) }}
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </template>
        <template v-else>
          <b-card class="shadow-none m-0 p-0">
            <b-card-body>
              <!-- Card needed to give overlay some dimension -->
            </b-card-body>
          </b-card>
        </template>
      </b-overlay>
    </template>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import PageLayout from '@/components/PageLayout.vue';
import notify from '@/mixins/notify.mixin';
import OverlayLoading from '@/components/OverlayLoading.vue';
import Fuse from 'fuse.js';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ApplicationDetails from '@/views/all-state/application/ApplicationDetails.vue';
import ApplicationStudent from '@/views/all-state/application/ApplicationStudent.vue';
import ApplicationQuestions from '@/views/all-state/application/ApplicationQuestions.vue';
import ApplicationGrading from '@/views/all-state/application/ApplicationGrading.vue';
import popper from '@/mixins/popper.mixin';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import TableRowOptions from '@/components/TableRowOptions.vue';
import settingsMixin from '@/mixins/settings.mixin';
import { getStudentEvents } from '@/views/events/service';
import events from '@/mixins/event.mixin';

export default {
  name: 'BusRosterModal',
  components: {
    TableRowOptions,
    BCardActions,
    ApplicationGrading,
    ApplicationDetails,
    ApplicationStudent,
    ApplicationQuestions,
    OverlayLoading,
    PageLayout,
    vSelect,
    VuePerfectScrollbar
  },
  mixins: [notify, popper, settingsMixin, events],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    buses: {
      type: Array,
      required: true,
      default: () => []
    },
    events: {
      type: Array,
      default: () => []
    },
  },
  data() {
    /* eslint-disable global-require */
    return {
      logo: null,
      user: null,
      instrument: null,
      ensembles: {
        loading: true,
        items: []
      },
      studentEvents: {
        loading: true,
        items: [ ],
      },
      applications: {
        loading: false,
        loaded: 0
      },
      table: {
        busy: false,
        fields: [
          /*{ key: 'group', label: 'Group', class: 'page-break' },*/
          { key: 'student', label: 'Student', class: 'col-student' },
          { key: 'festival.name', label: 'Festival', class: 'col-festival' },
          { key: 'festival.zone.name', label: 'Zone', class: 'col-zone' },
          { key: 'ranking.score', label: 'Score', class: 'col-score' },
          { key: 'ranking.local', label: 'Local Rank', class: 'col-local' },
          { key: 'selection.ranking.state', label: 'State Rank', class: 'col-state' },
          { key: 'selection.ensemble', label: 'Ensemble', class: 'col-ensemble' },
          { key: 'selection.part', label: 'Part', class: 'col-part' },
          /*{ key: 'row-options', label: '', tdClass: 'align-middle', thClass: 'border-0', class: 'table-row-options' },*/
        ],
        filter: {
          group: null,
          ensemble: null,
          part: null
        },
        search: null,
        items: [],
        initialItems: [],
        subscription: {
          onUpdate: null
        },
      },
      save: {
        complete: false,
        processing: false,
        status: '',
        changes: [],
        computedChanges: [],
        errors: [],
        progress: 0,
        processed: []
      },
      ui: {
        layout: {
          showLeftColumn: true,
        },
        changes: {
          expanded: false
        },
        processed: {
          expanded: true
        },
        potentialIssues: {
          expanded: false
        },
        error: {
          show: false,
          message: ''
        }
      },
      rules: {
        state: { required: false, numeric: true, min_value: 0, max_value: 999 },
        ensemble: { required: false },
        part: { required: false },
      },
      icon: 'fas fa-check',
    }
  },
  computed: {
    sortedItems() {
      if(this.table.busy || this.table.items.length === 0) {
        return []
      }
      const fuse = new Fuse(this.table.items, {
        useExtendedSearch: true,
        threshold: 0.2,
        keys: [
          'id',
          'group',
          'selection.ensemble',
          'selection.part',
          'student.id',
          'student.name.full',
        ]
      })

      const query = { $and: [ ] }
      if(this.table.filter.group) { query.$and.push({group: `'${this.table.filter.group}` }) }
      if(this.table.filter.ensemble) { query.$and.push({'selection.ensemble': `'${this.table.filter.ensemble}` }) }
      if(this.table.filter.part) { query.$and.push({'selection.part': `'${this.table.filter.part}` }) }

      if(this.table.search) {
        query.$and.push({
          $or: [
            { id: `'${this.table.search}` },
            { 'student.id': `'${this.table.search}` },
            { 'student.name.full': `'${this.table.search}`}
          ]
        })
      }
      let items;
      if(query.$and.length) {
        items = fuse.search(query).map(({ item }) => item).sort(this.compareItems())
      }
      else {
        items = this.table.items.slice().sort(this.compareItems())
      }

      return items.map((item, index) => ({...item,
        tabIndex: {
          state: (index + 1000),
          ensemble: (index + 1000) + this.table.items.length,
          part: (index + 1000) + (this.table.items.length * 2)
        }
      }))
    },
  },
  watch: {
    events: {
      deep: true,
      handler(value) {
        this.studentEvents.items = value
      }
    },
  },
  mounted() {
    window.addEventListener('afterprint', this.handleAfterPrint);
  },
  beforeDestroy() {
    window.removeEventListener('afterprint', this.handleAfterPrint);
  },
  methods: {
    async refresh() {
      await this.listStudentEvents()
    },
    async load() {
      if(!this.events.length) {
        await this.listStudentEvents()
      }
      else {
        /*const arrayOfObjects = Array.from({ length: 42 }, (_, index) => ({
          id: `${index + 1}-ae4f3269-6449-4104-ba5d-270071c52245`,
          studentId: '4fcc3412-f083-4867-8a70-1359f41464ff',
          busId: 'a5988bec-5e3c-483a-ba1e-4803826c7056',
          chaperoneId: null,
          hotelId: null,
          room: null,
          notes: [
            {
              id: '27381d73-8e5b-404e-ab2e-191bea207f68',
              type: 'bus',
              //text: 'abcdefghijklmnopqrstuvwxyz-abcdefghijklmnopqrstuvwxyz-'
              text: 'abcdefghijklmnopqrstuvwxyz-abcdefghijklmnopqrstuv'
            }
          ],
          student: {
            id: '4fcc3412-f083-4867-8a70-1359f41464ff',
            name: {
              first: 'abcdefghijklmnopqrstuvwxyz-ab',
              last: ''
            },
            sex: null,
            gender: null,
            applications: {
              items: [
                {
                  id: 'eca0f7ec-08c4-457c-999b-e439ec918fb8',
                  instrument: {
                    id: '5b909c56-95b2-443d-aa69-e65ecec919c2',
                    name: 'Jazz Alto Saxophone'
                  },
                  selection: {
                    id: 'e01e3d71-d614-4cbf-bfde-09fe9948596b',
                    ensemble: {
                      id: 'b2127082-a989-4d51-b4d1-2afc291935f7',
                      name: 'Symphony Orchestra'
                    },
                    part: '1',
                    accepted: true
                  }
                }
              ]
            }
          },
          status: 'saved'
        }));
        console.log(arrayOfObjects, this.events)*/

        this.studentEvents.items = this.sortItems({
          by: ['student.name.last', 'student.name.first'],
          desc: false
        }, this.events) //this.events

        this.studentEvents.loading = false
      }

      if(!this.logo) {
        this.logo = require('@/assets/images/logo/logo5-blue-white-gold.svg');
      }

      await this.$nextTick(() => {
        setTimeout(() => {
          window.print()
        }, 500);
      })
    },

    handleAfterPrint() {
      this.$bvModal.hide(this.id)
    },

    async listStudentEvents() {
      this.studentEvents.loading = true;
      await getStudentEvents({ includeNotes: true }).then(students => {
        this.studentEvents.items = students;
        this.studentEvents.loading = false;
        this.studentEvents.loaded = true;
      })
    },
    getStudentEventsForBus(busId) {
      return this.studentEvents?.items?.filter(studentEvent => studentEvent.busId === busId) ?? []
    },
    getBusNotesFromStudentEvent(event) {
      return event.notes?.filter(note => note.type === 'bus').map(note => note.text).join(',')
    },

    /** Validation **/
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationClass(validationContext) {
      const state = this.getValidationState(validationContext)
      if(state === null) return null
      return state ? 'is-valid' : 'is-invalid'
    },
  }
}
</script>

<style lang="scss">
@media print {
  .print-logo {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    object-fit: cover;
    vertical-align: middle;
  }

  #app {
    visibility: hidden;
    display: none;
  }

  table {
    page-break-after: always;
    break-after: page;

    /** {
      font-size: 11px;
    }*/

    caption {
      display: table-caption;
      margin: 0 auto 0 auto;
    }

    thead {
      page-break-before:unset!important;
      page-break-inside: unset!important;
      page-break-after:unset!important;
    }

    td {
      padding-top: 1.6px!important;
      padding-bottom: 1.6px!important;
    }
  }

  .modal {
    padding-left: 0 !important;
    position: relative;

    .modal-dialog {
      width: 100vw;
      max-width: none;
      margin: 0;

      span[tabindex] {
        visibility: hidden;
        display: none;
      }

      .modal-content {
        height: 100%;
        border: 0;

        .modal-body {
          overflow-y: visible;

          .b-overlay-wrap {
            overflow: hidden;

            .b-overlay {
              visibility: hidden;
              display: none;
            }
          }
        }
      }

      .modal-footer {
        visibility: hidden;
        display: none;
      }
    }
  }

  .modal-backdrop {
    visibility: hidden;
    display: none;
  }
}
</style>


