<template>
  <page-layout ref="layout" :show-print-option="false" class="d-print-none" @refresh="refresh">
    <template #breadcrumbs="{ year }">
      <b-breadcrumb-item :text="`Events - ${year}`" />
      <template v-if="id">
        <b-breadcrumb-item text="Hotels" :to="{ name: 'events-hotels', params: { id: null } }" />
        <b-breadcrumb-item v-if="selected.hotel" :text="selected.hotel.name" active />
      </template>
      <template v-else>
        <b-breadcrumb-item text="Hotels" active />
      </template>
    </template>

    <template v-if="hasChanges" #actions="{ }">
      <b-button v-b-tooltip="'Revert Changes'" variant="link" size="sm" class="btn-icon mr-50" @click="resetSelectedHotel">
        <b-icon-arrow-counterclockwise />
      </b-button>
      <can  do="update" on="events-hotels">
        <b-button v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateHotel">
          <b-icon-save2 />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="events-hotels">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <can do="delete" on="events-hotels">
          <b-dropdown-item class="table-row-option-delete"
                           @click="$refs.layout.confirmDelete(selected.hotel, deleteHotel, cascadeConfirmDeleteOptions)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </can>
        <b-dropdown-divider/>
      </can>
      <b-dropdown-item @click="signInOnly = !signInOnly">
        <feather-icon icon="EditIcon"/>
        <span class="align-middle ml-50">Sign In Only Mode</span>
      </b-dropdown-item>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
      <b-dropdown-divider/>
      <b-dropdown-item @click="sidebar = !sidebar">
        <feather-icon icon="SidebarIcon"/>
        <span class="align-middle ml-50">{{ sidebar ? 'Hide' : 'Show' }} Sidebar</span>
      </b-dropdown-item>
    </template>

    <template #loading="{ state }">
      <overlay-loading :items="[
          { state: hotels.loading, desc: 'Loading Hotels'},
          { state: state.loading, desc: 'Rendering Template'},
          ]">
      </overlay-loading>
    </template>

    <template #content="{ state }">
      <event-layout :show-sidebar="(sidebar && !signInOnly)" :auto-toggle-sidebar="false">
        <template #sidebar>
          <!-- Hotels List -->
          <events-nav title="Hotels"
                      :selected-id="id"
                      :items="computedHotels"
                      :filters="hotels.filters"
                      :sorting="hotels.sorting"
                      @search="(text) => hotels.search = text"
                      @sort="(sorting) => hotels.sorting = sorting"
                      @select="(item) => selectHotel(item)"
                      @deselect="deselectHotel">

            <template #item="{ item, emptyTextClass }">
              <span v-if="item.name">{{ item.name }}</span>
              <span v-else :class="emptyTextClass">Unnamed Hotel</span>
            </template>

            <template #actions>
              <can v-if="!signInOnly" do="create" on="events-hotels">
                <b-button size="xs" variant="link" class="btn-icon round px-50" @click="addHotel">
                  <b-icon-plus />
                </b-button>
              </can>
            </template>
          </events-nav>
          <!-- Print Card -->
          <registration-card v-model="groups" @ready="openRegistrationModal('hotels-registration')" />
          <registration-modal id="hotels-registration" :hotels="hotels.items" :groups="groups"/>
        </template>
        <template #content>
          {{ hasChanges }}

          <template v-if="selected.hotel">
            <b-card v-if="!signInOnly">
              <b-row>
                <!-- Avatar -->
                <b-col align-self="start" cols="auto">
                  <b-avatar variant="primary" size="45px" badge-variant="white">
                    <font-awesome-icon :icon="icon" size="2x"></font-awesome-icon>
                  </b-avatar>
                </b-col>
                <!-- Details -->
                <b-col align-self="center">
                  <!-- Details - Name -->
                  <b-row>
                    <b-col align-self="start" cols="6">
                      <validation-provider v-slot="validationContext" vid="name" name="Name" rules="required">
                        <b-form-group label="Name" label-for="name-input" :invalid-feedback="validationContext.errors[0]">
                          <b-input id="name-input" v-model="selected.hotel.name" :disabled="!state.editing"></b-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col align-self="start" cols="3">
                      <validation-provider v-slot="validationContext" vid="phone" name="Phone" rules="required">
                        <b-form-group label="Phone" label-for="phone-input" :invalid-feedback="validationContext.errors[0]">
                          <b-input id="phone-input"
                                   v-model="selected.hotel.phone.number"
                                   v-mask="'(###) ###-#### x####'"
                                   :disabled="!state.editing" />
                          <!--'(###) ###-#### ??#?#?#?#'-->
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col align-self="start" cols="3">
                      <validation-provider v-slot="validationContext" vid="medicalSuite" name="Medical Suite" rules="required">
                        <b-form-group label="Medical Suite" label-for="medicalSuite-input" :invalid-feedback="validationContext.errors[0]">
                          <b-input id="medicalSuite-input" v-model="selected.hotel.medicalSuite" :disabled="!state.editing"></b-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>

            <b-alert :show="hasChanges" variant="warning" class="d-print-none mb-2" dismissible>
              <b-icon-info-circle-fill font-scale="1"/> <strong class="align-middle ml-50 font-small-3">Unsaved Changes</strong>
              <p class="align-middle font-small-3">
                Make sure you save before switching to another Hotel. Unsaved changes will be lost.
              </p>
            </b-alert>

            <!-- Stats -->
            <b-row v-if="!signInOnly">
              <b-col cols="12" class="col-xxl-4 col-xxxl">
                <!-- Room -->
                <b-card no-body>
                  <b-card-body>
                    <b-row>
                      <b-col align-self="center" cols="auto">
                        <b-avatar :variant="`light-primary`" size="45">
                          <font-awesome-icon icon="fa-solid fa-bed" size="2x" />
                        </b-avatar>
                      </b-col>
                      <b-col align-self="center">
                        <b-form-group label="Rooms" label-for="rooms-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                          <b-form-input
                              id="rooms-input" :disabled="true" :value="selectedHotelRoomNumbers.length"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :class="['w-100', !state.editing ? 'form-control-plaintext' : 'form-control-plaintext']"/>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col cols="12" class="col-xxl-4 col-xxxl">
                <!-- Students -->
                <b-card no-body>
                  <b-card-body>
                    <b-row>
                      <b-col align-self="center" cols="auto">
                        <b-avatar :variant="`light-primary`" size="45">
                          <font-awesome-icon icon="fa-solid fa-graduation-cap" size="2x" />
                        </b-avatar>
                      </b-col>
                      <b-col align-self="center">
                        <b-form-group label="Students" label-for="students-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                          <b-form-input
                              id="students-input" :disabled="true" :value="selected.hotel.studentEvents.items.length"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :class="['w-100', !state.editing ? 'form-control-plaintext' : 'form-control-plaintext']"/>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
              <b-col cols="12" class="col-xxl-4 col-xxxl">
                <!-- Chaperones -->
                <b-card no-body>
                  <b-card-body>
                    <b-row>
                      <b-col align-self="center" cols="auto">
                        <b-avatar :variant="`light-primary`" size="45">
                          <font-awesome-icon icon="fa-solid fa-people-roof" size="2x" />
                        </b-avatar>
                      </b-col>
                      <b-col align-self="center">
                        <b-form-group label="Chaperones" label-for="chaperones-input" class="m-0 p-0 mt-05" label-class="mb-n05 font-weight-bold">
                          <b-form-input
                              id="chaperones-input" :disabled="true" :value="selected.hotel.chaperones.items.length"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :class="['w-100', !state.editing ? 'form-control-plaintext' : 'form-control-plaintext']"/>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>

            <b-tabs v-model="selected.hotel.tab" nav-class="d-flex flex-row justify-content-start">
              <b-tab v-if="!signInOnly" title="Room Assignments">
                <room-assignments :selected="selected" />
              </b-tab>
              <b-tab title="Registration Sign-In" lazy>
                <b-overlay :show="selected.hotel.studentEvents.loading || selected.hotel.chaperones.loading" :opacity="1" bg-color="white" style="min-height: 100px">
                  <hotel-registration :groups="groups" :selected="selected" />
                </b-overlay>
              </b-tab>
              <template #tabs-end>
                <template v-if="selectedHotelStudentEvents.length">
                  <b-button v-if="selected.hotel.tab === 1" variant="link" class="ml-auto" @click="openRegistrationModal('hotel-registration')">
                    <b-icon-printer/>
                  </b-button>
                  <registration-modal id="hotel-registration"
                                      :hotels="[selected.hotel]"
                                      :groups="groups"
                                      :events="selected.hotel.studentEvents.items.filter(e => e.hotelId === selected.hotel.id)" />
                </template>
              </template>
            </b-tabs>
          </template>
          <template v-else>
            <b-card>
              No Hotel Selected
            </b-card>
          </template>
        </template>
      </event-layout>
    </template>

    <template #debug>
      <debug title="Hotels" collapsed>{{ hotels }}</debug>
    </template>
  </page-layout>
</template>

<script>
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import PageLayout from '@/components/PageLayout.vue';
import RoomModal from '@/views/events/hotels/RoomModal.vue';
import BCardActions from '@core/components/b-card-actions/BCardActions.vue';
import {
  getHotels,
  createHotel,
  updateHotel,
  deleteHotel,
  getChaperonesByHotelId,
  getStudentEventsByHotelId,
  Room, Occupant, RegistrationGroup,
} from '@/views/events/service';
import notify from '@/mixins/notify.mixin';
import HotelRoom from '@/views/events/hotels/HotelRoom.vue';
import vSelect from 'vue-select';
import OverlayLoading from '@/components/OverlayLoading.vue';
import RegistrationModal from '@/views/events/hotels/RegistrationModal.vue';
import RegistrationCard from '@/views/events/hotels/RegistrationCard.vue';
import Fuse from 'fuse.js';
import events from '@/mixins/event.mixin';
import HotelRegistration from '@/views/events/hotels/HotelRegistration.vue';
import EventsNav from '@/views/events/EventsNav.vue';
import { cascadeConfirmDeleteOptions, cascadeDeleteHotel } from '@/graphql/cascade/hotel';
import EventLayout from '@/views/events/EventLayout.vue';
import BusRosterModal from '@/views/events/buses/BusRosterModal.vue';
import RoomAssignments from '@/views/events/hotels/RoomAssignments.vue';
import { mask } from 'vue-the-mask'

export default {
  name: 'Hotels',
  directives: { mask },
  components: {
    RoomAssignments,
    BusRosterModal,
    EventLayout,
    EventsNav,
    HotelRegistration,
    RegistrationCard,
    RegistrationModal,
    OverlayLoading,
    vSelect,
    HotelRoom,
    PageLayout,
    RoomModal,
    BCardActions,
    draggable,
    VuePerfectScrollbar,
  },
  mixins: [ events, notify ],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      signInOnly: false,
      selected: {
        tab: 0,
        group: null,
        hotel: null,
        initial: null,
        loading: true,
      },
      hotels: {
        loading: true,
        loaded: false,
        items: [],
        search: null,
        filters: null,
        sorting: {
          by: ['name'],
          desc: false,
          drag: false,
          options: [
            { label: 'Name', value: 'name'},
          ]
        },
      },
      groups: [
          new RegistrationGroup('A', 'Z'),
      ],
      icon: 'fas fa-hotel',
      sidebar: true,
      mask: [
        '(', /\d/, /\d/, /\d/, ') ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/,
        ' ', 'x', /\d/, /\d/, /\d/, /\d/ // Optional extension section
      ],
      cascadeConfirmDeleteOptions
    }
  },
  computed: {
    selectedHotelRoomNumbers() {
      if(this.selected.hotel.studentEvents.loading || this.selected.hotel.chaperones.loading) return []
      const roomNumbers = this.selected.hotel.studentEvents.items.map(e => e.room).concat(this.selected.hotel.chaperones.items.map(c => c.room))
      return Array.from(new Set(roomNumbers)).filter(r => r !== null).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
    },
    selectedHotelStudentEvents() {
      if(this.selected.hotel.studentEvents.loading) return []
      const fuse = new Fuse(this.selected.hotel.studentEvents.items, {
        useExtendedSearch: true,
        keys: [
          'name',
          'name',
        ]
      })
      const query = { $and: [ ] }
      if(this.selected.hotel.studentEvents.search) {
        query.$and.push({
          $or: [
            { 'name.first': `'${this.selected.hotel.studentEvents.search}` },
            { 'name.last': `'${this.selected.hotel.studentEvents.search}`}
          ]
        })
      }

      let items;
      if(query.$and.length) { items = fuse.search(query).map(({ item }) => item) }
      else { items = this.selected.hotel.studentEvents.items }
      return items
    },
    computedHotels() {
      const fuse = new Fuse(this.hotels.items, {
        useExtendedSearch: true,
        keys: [
          'id',
          'name',
        ]
      })

      const query = { $and: [ ] }
      if(this.hotels.search) {
        query.$and.push({
          $or: [
            { name: `'${this.hotels.search}` },
          ]
        })
      }

      let items;
      if(query.$and.length) { items = fuse.search(query).map(({ item }) => item) }
      else { items = this.hotels.items }
      return items
    },
    hasChanges() {
      const { hotel, initial } = this.selected
      const { name, phone, medicalSuite } = hotel ?? {}
      const { name: initialName, phone: initialPhone, medicalSuite: initialMedicalSuite } = initial ?? {}
      return JSON.stringify({ name, phone, medicalSuite }) !== JSON.stringify({ name: initialName, phone: initialPhone, medicalSuite: initialMedicalSuite })
    }
  },
  async created() {
    await this.listHotels()
    await this.$nextTick(() => {
      if(this.id) this.selectHotel(this.hotels.items.find(hotel => hotel.id === this.id))
      setTimeout( () => { this.$refs.layout.state.loading = false }, 500);
    })
  },
  methods: {
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.listHotels()
      this.$refs.layout.state.loading = false
    },

    async listHotels() {
      this.hotels.loading = true;
      await getHotels().then(hotels => {
        this.hotels.items = hotels;
        this.hotels.loading = false;
        this.hotels.loaded = true;
      })
    },
    async addHotel() {
      await createHotel().then(hotel => {
        this.hotels.items.push(hotel)
        this.selected.hotel = hotel
        this.notify({ title: 'Success', text: 'Hotel was successfully created', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Hotel failed to create', icon: this.icon, variant: 'danger'});
      })
    },
    async updateHotel() {
      const { id, name, phone, medicalSuite } = this.selected.hotel;
      await updateHotel({ id, name, phone, medicalSuite }).then(() => {
        this.updateSelectedHotelInitial()
        this.notify({ title: 'Success', text: 'Hotel was successfully updated', icon: this.icon, variant: 'success' });
      })
      .catch(err => {
        console.error(err)
        this.notify({ title: 'Error', text: 'Hotel failed to update', icon: this.icon, variant: 'danger'});
      })
    },
    async deleteHotel(hotel, swalCallback) {
      try {
        await this.cascadeDeleteHotel(hotel.id, swalCallback)
        this.hotels.items = this.hotels.items.filter(h => h.id !== hotel.id)
        this.selected.hotel = null
        this.notify({ title: 'Success', text: 'Hotel was successfully deleted', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'Hotel failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for Swal
      }
    },
    cascadeDeleteHotel,

    async selectHotel(hotel) {
      this.selected = { ...this.selected, loading: true, hotel: null, initial: null };
      if(!hotel) {
        await this.$router.push({ name: 'events-hotels', params: { id: null } })
      }
      else {
        if(!hotel?.chaperones?.items?.length) {
          hotel.chaperones = { loading: true, items: [] }
        }
        if(!hotel?.studentEvents?.items?.length) {
          hotel.studentEvents = { loading: true, items: [] }
        }

        this.selected.hotel = hotel;
        this.selected.initial = JSON.parse(JSON.stringify(hotel));
        this.selected.loading = false;
        if(this.$route.params.id !== hotel.id) {
          await this.$router.push({ name: 'events-hotel', params: { id: hotel.id } })
        }

        if(this.selected.hotel?.chaperones.loading) {
          await getChaperonesByHotelId(hotel.id).then(chaperones => {
            hotel.chaperones.items = chaperones;
            hotel.chaperones.loading = false;
          })
        }

        if(this.selected.hotel?.studentEvents.loading) {
          await getStudentEventsByHotelId(hotel.id, { includeSignature: true })
          .then(studentEvents => {
            hotel.studentEvents.items = studentEvents;
            hotel.studentEvents.items.sort((a, b) => (a.student?.name?.last ?? '').localeCompare(b.student?.name?.last ?? ''))
            hotel.studentEvents.loading = false;
          })
        }

        this.selected.hotel = hotel;
        this.selected.initial = JSON.parse(JSON.stringify(hotel));
        this.selected.loading = false;
      }
      this.selected.loading = false;
    },
    async deselectHotel() {
      this.selected.hotel = null;
      if(this.$route.params.id !== null) {
        await this.$router.push({ name: 'events-hotels', params: { id: null } })
      }
    },
    resetSelectedHotel() {
      this.selected.hotel = JSON.parse(JSON.stringify(this.selected.initial));
      // Find the index of the bus with the matching id in the hotels array
      const index = this.hotels.items.findIndex(hotel => hotel.id === this.selected.hotel.id);

      // Replace the bus at the found index with the selected bus
      if (index !== -1) {
        this.hotels.items.splice(index, 1, this.selected.hotel);
      }
    },
    updateSelectedHotelInitial() {
      this.selected.initial = JSON.parse(JSON.stringify(this.selected.hotel))
    },


    openRegistrationModal(modalId) {
      this.$bvModal.show(modalId)
    },
    isSelectedGroup(group) {
      return this.selected.group && this.selected.group.id === group.id
    },
    getFloorNumber(number) {
      return Number(String(number)[0])
    },
    getFloorRooms(floor) {
      const rooms = []
      this.selectedHotelRoomNumbers.filter(roomNumber => this.getFloorNumber(roomNumber) === floor).forEach(roomNumber => {
        const occupants = []
        occupants.push(...this.selected.hotel.chaperones.items.filter(chaperone => chaperone.room === roomNumber).map(chaperone => new Occupant('chaperone', chaperone)))
        occupants.push(...this.selected.hotel.studentEvents.items.filter(event => event.room === roomNumber).map(event => new Occupant('student', {
          ...event.student,
          event: {
            id: event.id,
            chaperoneId: event.chaperoneId,
            chaperone: event.chaperone,
            hotelId: event.hotelId,
            hotel: event.hotel,
            room: event.room,
          }
        })))
        rooms.push(new Room(roomNumber, occupants))
      })
      return rooms.sort((a, b) => a.number - b.number)
    },
    getApplicationWithSelection(applications) {
      return applications.items.find(application => application.selection?.accepted)
    },
  },
}
</script>

<style scoped>

.ps-hotels {
  max-height: 50vh;
}

  .draggable-list-group .list-group-item:hover {
    background-color: inherit;
  }

  .draggable-list-group.list-group .list-group-item:hover {
    background-color: unset!important;
  }

  .list-group:empty,
  .list-group > div:empty {
    padding:1rem;
    margin-right: .5rem;
    text-align:left;
    border: 1px dashed rgba(34, 41, 47, 0.125);
  }

  .students.list-group:empty:before,
  .students.list-group > div:empty:before {
    content: 'No Students Selected...';
  }

  .chaperones.list-group:empty:before,
  .chaperones.list-group > div:empty:before {
    content: 'No Chaperones Selected...';
  }

  .col.pr-75 {
    padding-right: 0.75rem;
  }
</style>
